import axios from "axios";

export const API = axios.create({
  baseURL: "https://prod-api.edge196.com/",
});

export const IP_URL = "https://api.cpay.digital/fetch/details/location";

export const BASE_URL = "https://prod-api.edge196.com";

export const AUTH_URL = `${BASE_URL}/api/auth`;

export const KYC_ROOT_URL = `https://prod-api.edge196.com/api/kyc`;

export const KYC_URL = `${BASE_URL}/api/kyc`;

export const SWAP_URL = `${BASE_URL}/api/swap`;

export const SUB_SUB = "https://test-api.sumsub.com";

export const BLACKLISTED_COUNTRIES = [
  // 'in',
  "us",
  "af",
  "cu",
  "cd",
  "ir",
  "sd",
  "sy",
  "ua",
  "pk",
  "dz",
  "bo",
  "bd",
  "mk",
  "sa",
  "qa",
  "vu",
  "cn",
  "ec",
  "id",
  "ma",
  "zm",
  "np",
  "eg",
  "as",
  "co",
  "ru",
  "rs",
  "by",
  "bi",
  "cf",
  "iq",
  "lb",
  "ly",
  "ni",
  "kp",
  "so",
  "sd",
  "sy",
  "ua",
  "ve",
  "ye",
  "zw",
];
