import React, { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import WOW from "wowjs";
import axios from "axios";

import team_bg from "../../images/team_bg.png";
import PageTitle from "../reusecomponents/PageTitle";
import Product from "./product";

const Project = () => {
 useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

   
  }, []);

  return (
    <React.Fragment>
      <main>
        <Parallax
          blur={0}
          bgImage={team_bg}
          bgImageAlt="VENTURE CAPITAL INVESTING"
          strength={300}
          className="team-parallax"
        >
          <section className="eco-system-se position-relative who-bg our-team">
            <div className="for-shape position-absolute z-index-0"></div>
            <PageTitle
              backLink="/"
              backText="Home"
              correntPage="Portfolio Spotlights"
              title="Portfolio Spotlights"
              title2="Portfolio Spotlights"
              text=""
            />

            <div className="main-team-section position-relative">
              <div className="container-fluid product-box-wrap">
                <div className="layout--tabs">
                  {/* <div className="container"> */}
                   <Product />
                  {/* </div> */}
                </div>
              </div>
            </div>
          </section>
        </Parallax>
        <section className="advantage-over position-relative becom-our">
          <div className="container wow fadeInUp">
            <div className="inner-text ff-muli-b text-white m-0">
              <h2 className="text-uppercase text-white">JOIN OUR TEAM</h2>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <p className="">
                  Interested in becoming a part of EDGE196? Contact us at{" "}
                  <a href="mailto:info@edge196.com">info@edge196.com</a> with
                  your resume and a brief on how you would benefit the EDGE196
                  mission.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};
export default Project;
