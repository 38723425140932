import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CheckLogin } from "./adminUtil";
import LeftMenu from "./LeftMenu";

const viewTeam = () => {
  const [teamData, setTeamData] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [dragId, setDragId] = useState();
  useEffect(() => {
    getTeam();
    if (sessionStorage.getItem("token")) {
      setIsLogin(true);
    }
  }, []);

  const getTeam = async () => {
    const res = await axios.get(
      "https://prod-api.edge196.com/api/team/getdata"
    );

    if (res.status === 200) {
      setTeamData(res.data.message);
    }
  };
  const deleteTeam = async (id) => {
    const data = {
      _id: id,
    };
    try {
      const res = await axios.post(
        "https://prod-api.edge196.com/api/team/delete",
        data
      );
      console.log(res);
      if (res.status === 200) {
        getTeam();
        alert("Deleted Successfully!");
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleDragStart = (e) => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = (e) => {
    console.log(e.currentTarget.id);
    const dragItem = teamData.find((person) => person._id === dragId);
    const dropItem = teamData.find(
      (person) => person._id === e.currentTarget.id
    );

    const newTeamData = teamData.map((person) => {
      if (person._id === dragId) {
        return dropItem;
      }
      if (person._id === e.currentTarget.id) {
        return dragItem;
      }
      return person;
    });
    setTeamData(newTeamData);
  };

  return (
    <div className="viewDataWrapper">
      <LeftMenu/>
      <h2>
        Team Member <Link to="/admin/add-team">Add New</Link>
      </h2>
      {isLogin ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Designation</th>
              <th>Country</th>
              <th>City</th>
              <th>Image</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {teamData &&
              teamData.map((data) => (
                <tr
                  key={data._id}
                  id={data._id}
                  draggable={true}
                  onDragStart={handleDragStart}
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <td>{data.name}</td>
                  <td>{data.email}</td>
                  <td>{data.designation}</td>
                  <td>{data.country}</td>
                  <td>{data.city}</td>
                  <td>
                    <img
                      src={
                        data.image.includes("amazonaws.com")
                          ? data.image
                          : `https://prod-api.edge196.com/team/${data.image}`
                      }
                    />
                  </td>
                  <td>
                    <button>
                      <Link to={`/admin/modify/${data._id}`}>Edit</Link>
                    </button>{" "}
                    &nbsp;
                    <button
                      onClick={() => {
                        deleteTeam(data._id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <CheckLogin setIsLogin={setIsLogin} />
      )}
    </div>
  );
};

export default viewTeam;
