import React,{useState} from 'react'
import axios from "axios";

export const login = async () =>{
    const [username, setUsername] = useState();
    const      [password, setPassword] = useState();
    const       loginSubmit = () =>{
              
          };

    

      return (
        <div className='formWrapper'>
        <h2></h2>
        <input type='text' name='name' placeholder='Name' onChange={(e)=>{setUsername(e.target.value)}}/>  
        <input type='password' name='email' placeholder='Email' onChange={(e)=>{setPassword(e.target.value)}}/>  
         
        <button onClick={loginSubmit}>Login</button>
    </div>

      )
}

const CheckLogin  = ({setIsLogin}) =>{
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const loginSubmit = async() =>{
        const data = {
            username:username,
            password:password
        }
        try {
            const res = await axios.post(
              "https://prod-api.edge196.com/api/team/login",
              data
            );
            if(res.status === 200){
                if (typeof(Storage) !== "undefined") {
                    // Store
                    sessionStorage.setItem("token", res.data.msg);
                    // Retrieve
                  if(sessionStorage.getItem("token")){
                    setIsLogin(true);
                  }
                  } else {
                    alert("Sorry, your browser does not support Web Storage");
                  }
    
            }
          } catch (ex) {
             alert("invalid user id or password!")
          }
          };
    return(
        <div>
            <div className='formWrapper'>
        <h2></h2>
        <input type='text' name='name' placeholder='username' onChange={(e)=>{setUsername(e.target.value)}}/>  
        <input type='password' name='email' placeholder='password' onChange={(e)=>{setPassword(e.target.value)}}/>  
         
        <button onClick={loginSubmit}>Login</button>
    </div>
        </div>
    )
}

export {CheckLogin}