import React, { Component, Suspense } from "react";
import SuspenseLoader from "./components/States/SuspenseLoader";
import Routes from "./routes";
import IdleTimer from "react-idle-timer";
import { notification } from "antd";
import axios from "axios";
import { AUTH_URL } from "./utils/variables";
import "./App.css";
import UnderConstruct from "./pages/UnderConstruct";


class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  logout = (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${AUTH_URL}/logout`, config)
      .then(() => {
        localStorage.clear();
        localStorage.setItem("cookie-accepted", "true");
        notification.info({
          message: "Logged out",
          description:
            "You have been logged out due to inactivity. Please log in again",
          onClose: () => (window.location = "/"),
          duration: 0,
          style: {
            position: "relative",
            top: "3.25rem",
          },
        });
      })
      .catch((_) => {
        localStorage.clear();
        localStorage.setItem("cookie-accepted", "true");
        notification.info({
          message: "Logged out",
          description:
            "You have been logged out due to inactivity. Please log in again",
          onClose: () => (this.window.location = "/"),
          duration: 0,
          style: {
            position: "relative",
            top: "3.25rem",
          },
        });
      });
  };

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 5}
          onIdle={this.handleOnIdle}
        />
        <Suspense fallback={<SuspenseLoader />}>
          {/* <UnderConstruct /> */}
            <Routes />
        </Suspense>
      </>
    );
  }
}

export default App;
