import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../images/edge196_gray.svg";
import axios from "axios";
import CryptoJS from "crypto-js";
import { AUTH_URL, IP_URL } from "../../utils/variables";
import { CircularProgress } from "@material-ui/core";
import { HomeTabContext } from "../../context/HomeTabContext";
import { Col, Row } from "react-bootstrap";
import Unvisible from "../../images/Unvisible.svg";
import visible from "../../images/visible.svg";
import { Spin } from "antd";

const Header = () => {
  const [loggingIn, setLoginState] = useState(false);
  const [ip, setIP] = useState("");
  const [os, setOS] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loggingOut, setLogoutState] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const handleMenu = () => setShowMenu(!showMenu);
  const { toggleHomeTab } = useContext(HomeTabContext);

  const history = useHistory();

  if (showMenu) {
    document.body.classList.add("body-scroll-fixed");
  } else {
    document.body.classList.remove("body-scroll-fixed");
  }

  const queryParentElement = (el, selector) => {
    el = el;
    const isIDSelector = selector.indexOf("#") === 0;

    if (selector.indexOf(".") === 0 || selector.indexOf("#") === 0) {
      selector = selector.slice(1);
    }

    while (el) {
      if (isIDSelector) {
        if (el.id === selector) {
          return el;
        }
      } else if (el.classList.contains(selector)) {
        return el;
      }
      el = el.parentElement;
    }
    return null;
  };

  const mousUp = (e) => {
    const parentElement = queryParentElement(e.target, ".side-menu");
    const $menu = document.getElementsByClassName("side-menu")[0];
    if (
      e.target !== $menu && // if the target of the click isn't the container...
      parentElement === null &&
      showMenu
    ) {
      handleMenu();
    }
  };

  // Header fixed
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
      document.body.classList.add("body-padTop");
    } else {
      setScrolled(false);
      document.body.classList.remove("body-padTop");
    }
  };

  useEffect(() => {
    fetchip();
    fetchos();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mouseup", mousUp);
  });

  const handleLogout = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setLogoutState(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(`${AUTH_URL}/logout`, config)
        .then((res) => {
          localStorage.clear();
          localStorage.setItem("cookie-accepted", true);
          window.location.reload();
        })
        .catch((_) => {
          localStorage.clear();
          localStorage.setItem("cookie-accepted", true);
          window.location.reload();
        });
    } else {
      localStorage.clear();
      localStorage.setItem("cookie-accepted", true);
      window.location.reload();
    }
  };

  const scrollTOWidget = (tab) => {
    setShowMenu(false);
    toggleHomeTab(tab, true);
    history.push("/");
  };

  const [showLogin, setShowLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleLoginShow = () => setShowLogin(!showLogin);
  const handlePasswordShow = () => setShowPassword(!showPassword);

  const storeKycStatus = (value) => {
    const encryptedValue = CryptoJS.AES.encrypt(value, "DvQZqPNNRh").toString();
    localStorage.setItem("isKycVerified", encryptedValue);
  };

  const fetchip = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        setIP(res.data.ip);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const fetchos = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }
    setOS(os);
  };

  const storeUser = (userData) => {
    const encryptedObject = CryptoJS.AES.encrypt(
      JSON.stringify(userData),
      "DvQZqPNNRh"
    ).toString();
    localStorage.setItem("UD", encryptedObject);
  };

  const handleLogin = () => {
    let error = false;
    if (!email.trim()) {
      error = true;
      setEmailError("Enter your email address");
    } else if (email && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      error = true;
      setEmailError("Enter a valid email address");
    }
    if (!password.trim()) {
      error = true;
      setPasswordError("Enter your password");
    }
    if (!error) {
      const data = {
        email: email.toLowerCase().trim(),
        password: password.trim(),
        ip: ip,
        os: os,
      };
      setLoginState(true);
      axios
        .post(`${AUTH_URL}/login`, data)
        .then((res) => {
          if (res.data.success) {
            if (res.data.is2faEnabled) {
              history.push("/two-factor-authentication", {
                email: res.data.email,
                phone: res.data.phone,
                type: res.data.twoFaType,
                redirect: {
                  page: "/",
                },
              });
            } else {
              localStorage.setItem("accessToken", res.data.accessToken);
              localStorage.setItem("login", true);
              localStorage.setItem("email", res.data.email);
              localStorage.setItem("isLoggedIn", true);
              storeKycStatus(
                res.data.kycStatus === "APPROVED_VERIFIED" ? "true" : "false"
              );
              const userInfo = {
                email: res.data.email,
                mobile: res.data.phone,
                session: { ...res.data.sessionDetails },
                wallet: { ...res.data.wallet },
                ...res.data.info,
              };
              storeUser(userInfo);
              window.location.reload();
            }
          } else {
            if (res.data.active === false) {
              localStorage.setItem("email", email);
              localStorage.setItem("isActivate", true);
              window.location = "/activate";
            } else {
              setLoginState(false);
              setLoginError(res.data.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoginState(false);
          setLoginError("Server error, Please contact support");
        });
    }
  };

  return (
    <>
      <div className={showMenu ? "body-scroll-dis" : ""}></div>
      <header className="w-100 header position-relative">
        <div className="fixed-header">
          <div className="container-fluid ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="logo">
                <a href="/">
                  <img src={Logo} alt="" />
                </a>
              </div>
              <div className="loginMenus d-flex align-items-center justify-content-between">
                {/* {localStorage.getItem("accessToken") ? (
                  <a
                    href="#/"
                    className="mr-md-5 mr-2 edge196_menuLink"
                    onClick={handleLogout}
                  >
                    Logout
                  </a>
                ) : (
                  <>
                    <a
                      href="#/"
                      className={`mr-md-5 mr-2 edge196_menuLink ${
                        showLogin ? "text-dark" : ""
                      }`}
                      onClick={handleLoginShow}
                    >
                      Login
                    </a>
                    <a
                      className="mr-md-5 mr-2 edge196_menuLink"
                      href="/register"
                    >
                      Register
                    </a>
                  </>
                )} */}

                <div className="">
                  <nav role="navigation" className="side-menu">
                    <i
                      className={`menu-toggle for-before-sprite position-absolute ${
                        showMenu ? "menu-open" : ""
                      }`}
                      onClick={handleMenu}
                    ></i>

                    <ul className={`menu ${showMenu ? "active" : ""}`}>
                      <li className="ff-muli-b">
                        <a href="/">Home</a>
                      </li>
                      <li className="ff-muli-b">
                        PRODUCTS
                        <ul className="ff-muli-l">
                          {/* <li>
                            {localStorage.getItem("accessToken") ? (
                              <a href="/dashboard/genesis-offer">
                                EDGE-X GENESIS
                              </a>
                            ) : (
                              <button onClick={() => scrollTOWidget("first")}>
                                EDGE-X GENESIS
                              </button>
                            )}
                          </li> */}
                          {/* <li>
                            {localStorage.getItem("accessToken") ? (
                              <a href="/dashboard/momentum-sale">
                                EDGE-X MOMENTUM SALE
                              </a>
                            ) : (
                              <button onClick={() => scrollTOWidget("second")}>
                                EDGE-X MOMENTUM SALE
                              </button>
                            )}
                          </li> */}
                          {/* <li>
                            {localStorage.getItem("accessToken") ? (
                              <a href="/dashboard/universal-swap">
                                EDGE-X UNIVERSAL SWAP
                              </a>
                            ) : (
                              <button onClick={() => scrollTOWidget("third")}>
                                EDGE-X UNIVERSAL SWAP
                              </button>
                            )}
                          </li> */}
                          {/* <li>
                            {localStorage.getItem("accessToken") ? (
                              <a href="/dashboard/power-staking">
                                EDGE-X POWER STAKE
                              </a>
                            ) : (
                              <button onClick={() => scrollTOWidget("fourth")}>
                                EDGE-X POWER STAKE
                              </button>
                            )}
                          </li> */}
                          <li>
                            <a
                              href="https://invest.edge196.com/"
                              rel="noreferrer"
                              target="_blank"
                            >
                              EDGE-Q
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="ff-muli-b">
                        About Us
                        <ul className="ff-muli-l">
                          <li>
                            <a href="/our-story">Our Story</a>
                          </li>
                          <li>
                            <a href="/our-solution">Our Solution</a>
                          </li>
                          <li>
                            <a href="/team">Our Team</a>{" "}
                          </li>
                          {/* <li>
                            <a href="/project">Our projects</a>
                          </li> */}
                        </ul>
                      </li>
                      <li className="ff-muli-b">
                        Our community
                        <ul className="ff-muli-l">
                          <li>
                            <a href="/partners">Partners</a>
                          </li>
                          <li>
                            <a
                              href="https://www.labs196.com/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Accelerator Program
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/* <li className="ff-muli-b">
                        Events
                        <ul className="ff-muli-l">
                          <li>
                            <a href="https://events.edge196.com/partnerevents/">
                              Partnership Events
                            </a>
                          </li>
                          <li>
                            <a href="https://events.edge196.com/">
                              Competitions
                            </a>
                          </li>
                          <li>
                            <a href="https://events.edge196.com/hackathons/">
                              Hackathons
                            </a>
                          </li>
                          <li>
                            <a href="https://events.edge196.com/register/">
                              Register An Event
                            </a>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className="ff-muli-b"> */}
                      {/* Media */}
                      {/* <ul className="ff-muli-l"> */}
                      {/* <li>
                            <a href="/news">News</a>
                          </li> */}
                      {/* <li>
                            <a href="/press-releases">Press Releases</a>
                          </li>
                          <li>
                            <a href="/portfolio-spotlights">
                              Portfolio Spotlights{" "}
                            </a>
                          </li>
                          <li>
                            <a href="/edge196-on-twitter">
                              edge196 on Social Media{" "}
                            </a>
                          </li> */}
                      {/* </ul> */}
                      {/* </li> */}
                      <li className="ff-muli-b">
                        Get in touch
                        <ul className="ff-muli-l">
                          <li>
                            <a href="/contact-us">Contact us</a>
                          </li>
                          {/* <li>
                            <a href="/partners#become-partner">
                              Become a partner
                            </a>
                          </li> */}
                          {/* <li>
                            <a href="/work-with-us">Work with us </a>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li className="ff-muli-b">
                        {localStorage.getItem("accessToken") ? (
                          <a href="/dashboard/genesis-offer">Dashboard</a>
                        ) : (
                          <a href="/login">Login/Register</a>
                        )}
                      </li> */}
                      {localStorage.getItem("accessToken") ? (
                        <>
                          <li className="ff-muli-b">
                            <a href="/dashboard/wallet">Wallet</a>
                          </li>
                          <li className="ff-muli-b">
                            <a href="/dashboard/settings">Settings</a>
                          </li>
                          <li className="ff-muli-b">
                            {loggingOut ? (
                              <CircularProgress
                                size={15}
                                thickness={4}
                                style={{ color: "rgb(239, 134, 56)" }}
                              />
                            ) : (
                              <a href="#/" onClick={handleLogout}>
                                Log out
                              </a>
                            )}
                          </li>
                        </>
                      ) : null}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {showLogin && (
        <div className="edge196_LoginBox">
          <form>
            <Row>
              <Col md={6}>
                <div className="pb-1">
                  <input
                    autoFocus
                    tabIndex="0"
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                      setLoginError("");
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  {emailError && (
                    <span
                      className="edge196_forgotLink"
                      style={{
                        justifyContent: "flex-start",
                        paddingTop: "5px",
                      }}
                    >
                      {emailError}
                    </span>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="pb-1">
                  <input
                    tabIndex="1"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                      setLoginError("");
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  {passwordError && (
                    <span
                      className="edge196_forgotLink"
                      style={{
                        justifyContent: "flex-start",
                        paddingTop: "5px",
                      }}
                    >
                      {passwordError}
                    </span>
                  )}
                  <button
                    type="button"
                    className="edge196_showpasswordbtn"
                    onClick={handlePasswordShow}
                  >
                    <img
                      src={showPassword ? visible : Unvisible}
                      alt="password"
                    />
                  </button>
                </div>
              </Col>
              <Col md={12}>
                <div className="d-flex align-items-start justify-content-between">
                  <div
                    className="edge196_forgotLink"
                    style={{ justifyContent: "flex-start" }}
                  >
                    Forgot your password?
                    <button
                      tabIndex="3"
                      type="button"
                      className="focussable-button"
                      onClick={() => (window.location = "/forgot-password")}
                    >
                      Click here
                    </button>
                    .
                  </div>
                  <div className="">
                    <div className="edge196_forgotLink">
                      {loggingIn ? (
                        <Spin className="white-spinner" size="small" />
                      ) : (
                        <button
                          tabIndex="4"
                          type="button"
                          className="focussable-button"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {loginError && (
              <span
                className="edge196_forgotLink"
                style={{ paddingTop: "5px", textAlign: "right" }}
              >
                {loginError}
              </span>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default Header;
