import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { CheckLogin } from "./adminUtil";
import LeftMenu from "./LeftMenu";

const CandidatDetails = () => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [designation, setDesignation] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [linkedin, setlinkedin] = useState("");

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("contact", contact);
    formData.append("country", country);
    formData.append("city", city);
    formData.append("designation", designation);
    formData.append("linkedin", linkedin);

    let error = "";

    if (!name) {
      error = "Name is mandatory field!";
    } else if (!email) {
      error = "Email is mandatory field!";
    } else if (!designation) {
      error = "Designation is mandatory field!";
    } else if (!country) {
      error = "Country is mandatory field!";
    } else if (!city) {
      error = "City is mandatory field!";
    } else if (!linkedin) {
      error = "Linkedin is mandatory field!";
    } else if (!fileName) {
      error = "Picture is mandatory field!";
    }

    if (error) {
      alert(error);
    } else {
      try {
        const res = await axios.post(
          "https://prod-api.edge196.com/api/team/upload",
          formData
        );
        if (res.status === 200) {
          alert("New Member Added Successfully!");
        }
      } catch (ex) {
        console.log(ex);
      }
    }
  };

  return (
    <div className="App">
      <div>
      <LeftMenu />
      </div>
      <div className="addteam">
        <div className="formWrapper">
          <h2>
            Add New Member{" "}
            <Link to="/admin/view-team" className="add-new">
              View All
            </Link>
          </h2>
          <input
            type="text"
            name="name"
            placeholder="Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            type="text"
            name="email"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="number"
            name="contact"
            placeholder="Contact"
            onChange={(e) => {
              setContact(e.target.value);
            }}
          />
          <select
            onChange={(e) => {
              setDesignation(e.target.value);
            }}
          >
            <option value="">==SELECT DESIGNATION==</option>
            <option option="FOUNDER/CEO">FOUNDER/CEO</option>
            <option option="INVESTMENT">INVESTMENT</option>
            <option option="COMPLIANCE">COMPLIANCE</option>
            <option option="ASSET MANAGEMENT">ASSET MANAGEMENT</option>
            <option option="DEAL FLOW">DEAL FLOW</option>
            <option option="OUTREACH">OUTREACH</option>
            <option option="RESEARCH/OUTREACH">RESEARCH/OUTREACH</option>
            <option option="OPERATIONS">OPERATIONS</option>
            <option option="MARKETING">MARKETING</option>
            <option option="DEVELOPMENT">DEVELOPMENT</option>
            <option option="ADVISOR">ADVISOR</option>
            <option option='BUSINESS MANAGEMENT'>BUSINESS MANAGEMENT</option>
            <option option='PROJECTS'>PROJECTS</option>
          </select>
          <input
            type="text"
            name="country"
            placeholder="Country"
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
          <input
            type="text"
            name="linkedin"
            placeholder="linkedin link"
            onChange={(e) => {
              setlinkedin(e.target.value);
            }}
          />
          <label>Profile pic</label>
          <input type="file" onChange={saveFile} />
          <button onClick={uploadFile}>Upload</button>
        </div>
      </div>
    </div>
  );
};

const PageNotFound = () => {
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      setIsLogin(true);
    }
  }, []);

  return (
    <div className="notFoundWrapper">
      <div className="info">
        {isLogin ? <CandidatDetails /> : <CheckLogin setIsLogin={setIsLogin} />}
      </div>
    </div>
  );
};

export default PageNotFound;
