import React, { createContext, useState } from "react";

export const HomeTabContext = createContext({
  activeTab: "first",
  animateToWidget: false,
  toggleHomeTab: () => null,
});

const HomeTabContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState("first");
  const [animateToWidget, setAnimate] = useState(false);

  const toggleHomeTab = (value, redirect) => {
    setActiveTab(value);
    setAnimate(redirect);
  };

  return (
    <HomeTabContext.Provider
      value={{ activeTab, toggleHomeTab, animateToWidget }}
    >
      {children}
    </HomeTabContext.Provider>
  );
};

export default HomeTabContextProvider;
