import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CheckLogin } from "./adminUtil";
import LeftMenu from "./LeftMenu";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';



const NewsAndPress = () => {
  const [teamData, setTeamData] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [dragId, setDragId] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [data, setData] = useState({});
  const [richText, setRichText] = useState();
  const onChange = ({target}) =>{
    const {name, value} = target;
    setData({...data, [name]: value});
  };
  const onSubmit = async(e)=>{
    const payload= {
      blog_type:data.type ? data.type : 'news',
        title:data.title,
        keyword: data.keyword,
        content:richText,
        author:"admin",
        externalLink:data.externalLink,
        image:data.image
    
    }

    console.log("payload", payload)
    const res = await axios.post(
      "http://localhost:5000/api/blog/",
      payload
    );
    if(res.status === 200){
      alert("Blog Created Successfully!")
      handleClose();
      getTeam()
    }
    else{
      console.log("somethig went wrong!")
    }
  }
  useEffect(() => {
    getTeam();
    if (sessionStorage.getItem("token")) {
      setIsLogin(true);
    }
  }, []);

  const getTeam = async () => {
    const res = await axios.get(
      "http://localhost:5000/api/blog/"
    );
    if (res.status === 200) {
      setTeamData(res.data.message);
    }
  };
 
  const handleDragStart = (e) => {
    setDragId(e.currentTarget.id);
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const handleDrop = (e) => {
    console.log(e.currentTarget.id);
    const dragItem = teamData.find((person) => person._id === dragId);
    const dropItem = teamData.find(
      (person) => person._id === e.currentTarget.id
    );

    const newTeamData = teamData.map((person) => {
      if (person._id === dragId) {
        return dropItem;
      }
      if (person._id === e.currentTarget.id) {
        return dragItem;
      }
      return person;
    });
    setTeamData(newTeamData);
  };
console.log(teamData)
  return (
    <div className="viewDataWrapper">
      <LeftMenu/>
      <h2>
        News And Press Release &nbsp;
        <button className="btn btn-primary pull-right" onClick={() => setShow(!show)}>Add New</button>

      </h2>
      {isLogin ? (
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Type</th>
              <th>Keyword</th>
              <th>Externa lLink</th>
            </tr>
          </thead>
          <tbody>
            {teamData &&
              teamData.map((data, index) => (
                <tr
                  key={data._id}
                  id={data._id}
                  draggable={true}
                  onDragStart={handleDragStart}
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <td>{index+1}</td>
                  <td>{data.title}</td>
                  <td>{data.blog_type}</td>
                  <td>{data.keyword}</td>
                  <td style={{width:'300px'}}>{data.externalLink}</td>
                 
                  
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <CheckLogin setIsLogin={setIsLogin} />
      )}


<Modal show={show} onHide={handleClose} className="projectModal newsWrap" size="lg">
        <Modal.Header closeButton>
          <h4>Add new blog</h4> 
        </Modal.Header>

        <Modal.Body>
          <select className="form-control" name="type" onChange={onChange}>
            <option value="news">News</option>
            <option value="press">Press Release</option>
          </select>
          <input type="text" className="form-control" placeholder="Title" name='title' onChange={onChange}/>
          <input type="text" className="form-control" placeholder="Keword" name='keyword' onChange={onChange}/>
          <input type="text" className="form-control" placeholder="External Link" name='externalLink' onChange={onChange}/>
          <input type="text" className="form-control" placeholder="Image Link" name='image' onChange={onChange}/>
          <ReactQuill
            value={richText}
            theme="snow"
            name="content"
            onChange={setRichText}
            placeholder="Enter Description..."
            modules = {modules}
            formats={formats}
          />
          <br/>
          <button className="submit-btn" onClick={onSubmit}>Submit</button> 
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewsAndPress;
