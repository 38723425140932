import React, {memo} from 'react';
import {Link, useLocation } from 'react-router-dom';

const menu = [
    {
        name:"Team",
        path:"view-team" 
    },
    {
        name:"Add-Team",
        path:"add-team" 
    },
    {
        name:"Contact",
        path:"view-contact"
    },
    {
        name:"Project",
        path:"project-contact"
    },
    {
        name:"News|Press",
        path:"news-and-press-release"
    }
]
const LeftMenu = () =>{
    const location = useLocation();
    return(
        <>
        <div className="leftMenuWrapper">
           {
            menu.map((item, index)=>(
               <li  className={location.pathname.includes(item.path) && 'active'} key={index}> <Link to={`/admin/${item.path}`}>{item.name}</Link></li>
            ))
           }
        </div>
        </>
    )
}

export default memo(LeftMenu);