const NewsData = [
    {
        id:1,
        title:"US company signs LoI to invest ₹820 cr to produce geothermal power in U.P.",
        date:"30 March 2023",
        image:"https://images.hindustantimes.com/img/2023/01/31/550x309/Geothermal-energy-is-a-mature-renewable-energy-tec_1675191277839.jpg",
        link:"https://www.hindustantimes.com/cities/lucknow-news/us-company-signs-loi-to-invest-rs-820-cr-to-produce-geothermal-power-in-up-101675191278883.html?fbclid=IwAR0Fryf3e-fNxkMWEy7DWGdc4vRPcalAsnEtBeK0gG9XhPAa_xNzT_c7J9w"
    },
    {
        id:2,
        title:"Geothermal energy heats up in India with Rs 820 crore investment proposed in UP",
        date:"30 March 2023",
        image:"https://static.toiimg.com/imagenext/toiblogs/photo/blogs/wp-content/uploads/2022/12/20211005_151130_2-1-150x150.jpg",
        link:"https://timesofindia.indiatimes.com/blogs/the-write-wing/geothermal-energy-heats-up-in-india-with-rs-820-crore-investment-proposed-in-up/"
    },
    {
        id:3,
        title:"DOE Industrial Decarbonization Roadmap",
        date:"30 March 2023",
        image:"https://www.energy.gov/sites/default/files/styles/full_article_width/public/2022-09/AMO_decarb_Web_files-4Strategies.jpg?itok=7Lp2Vp7c",
        link:"https://www.energy.gov/eere/doe-industrial-decarbonization-roadmap"
    },
    {
        id:4,    
        title:"U.S.-UAE Signing of the Partnership for Accelerating Clean Energy",
        date:"30 March 2023",
        image:"",
        link:"https://www.state.gov/u-s-uae-signing-of-the-partnership-for-accelerating-clean-energy/"
    },
    {
        id:5,    
        title:"Top 100 Energy Leaders | Energy Magazine (energydigital.com)",
        date:"30 March 2023",
        image:"",
        link:"https://energydigital.com/magazine/top-100-energy-leaders?utm_source=EnergyTop100&utm_medium=Social"
    },
    {
        id:6,    
        title:"https://www.thinkgeoenergy.com/wp-content/uploads/2022/11/EXERGY_Logo_ColourPayoff-1024x369.png",
        date:"30 March 2023",
        image:"https://www.thinkgeoenergy.com/wp-content/uploads/2022/11/EXERGY_Logo_ColourPayoff-1024x369.png",
        link:"https://www.thinkgeoenergy.com/exergy-unveils-corporate-rebrand-to-reflect-evolution-and-new-strategy/"
    },
    {
        id:7,    
        title:"Fervo Energy signs 15-year PPA with Southern California electricity provider",
        date:"12 Aug 2022",
        image:"https://www.thinkgeoenergy.com/wp-content/uploads/2022/08/Fervo-Energy-1024x274.png",
        link:"https://www.thinkgeoenergy.com/fervo-energy-signs-15-year-ppa-with-southern-california-electricity-provider/"
    },
    {
        id:8,    
        title:"12.5 MW Star Peak geothermal project connected to grid in Nevada",
        date:"12 Oct 2022",
        image:"https://www.thinkgeoenergy.com/wp-content/uploads/2022/08/StarPeak_plant_OpenMountain_Nevada.png",
        link:"https://www.thinkgeoenergy.com/12-5-mw-star-peak-geothermal-project-connected-to-grid-in-nevada/?utm_source=linkedin&utm_medium=social&utm_campaign=news"
    },
    {
        id:9,    
        title:"66 of 79 parcels receive bids in BLM Nevada geothermal lease sale",
        date:"1 Sep 2022",
        image:"https://www.thinkgeoenergy.com/wp-content/uploads/2019/07/BattleMountains_Nevada-1024x768.jpg",
        link:"https://www.thinkgeoenergy.com/66-of-79-parcels-receive-bids-in-blm-nevada-geothermal-lease-sale/"
    },
    {
        id:10,    
        title:"First Iranian geothermal plant on the grid and operational",
        date:"12 Oct 2022",
        image:"https://www-thinkgeoenergy-com.cdn.ampproject.org/i/s/www.thinkgeoenergy.com/wp-content/uploads/2018/04/Sabalan_geothermalplant_Iran-768x576.jpeg",
        link:"https://www-thinkgeoenergy-com.cdn.ampproject.org/c/s/www.thinkgeoenergy.com/first-iranian-geothermal-plant-on-the-grid-and-operational/amp/"
    },
    {
        id:11,    
        title:"The clean, green electricity machine",
        date:"12 Oct 2022",
        image:"http://www.geothermex.com/images/banner-V2.jpg",
        link:"http://www.geothermex.com/"
    },
    {
        id:12,    
        title:"Iceland GeoSurvey, ÍSOR, is a leading provider of scientific and technical expertise to the geothermal industry in Iceland and abroad",
        date:"12 Oct 2022",
        image:"https://en.isor.is/wp-content/uploads/2022/04/joey-clover-0zZfAlsjJG8-unsplash.jpg",
        link:"https://en.isor.is/"
    },
    {
        id:13,    
        title:"Chevron and Baseload Capital create joint venture to explore geothermal development opportunities",
        date:"14 Dec 2022",
        image:"https://mb.cision.com/Public/17753/3684021/ad5862e918c5a415_800x800ar.png",
        link:"https://news.cision.com/baseload-capital-sweden-ab/r/chevron-and-baseload-capital-create-joint-venture-to-explore-geothermal-development-opportunities,c3684021"
    },
    {
        id:14,    
        title:"Chevron to create JV for U.S. geothermal projects",
        date:"2 Dec 2022",
        image:"https://www.reuters.com/resizer/oiMIbtXvEN5uDirztHwH9SPEUnA=/960x0/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/SRQZ64KLAFOHREHAD2X6CKX2CQ.jpg",
        link:"https://news.cision.com/baseload-capital-sweden-ab/r/chevron-and-baseload-capital-create-joint-venture-to-explore-geothermal-development-opportunities,c3684021"
    },
    {
        id:16,    
        title:"Baker Hughes Launches Consortium Exploring Technologies to Transform Abandoned Wells for Geothermal Energy Production",
        date:"8 Dec 2022",
        image:"https://www.bakerhughes.com/sites/bakerhughes/files/styles/large_2_1_2x_2880x1426_/public/2022-12/12-08-2022_geothermal_consortium_mtg_pr_1.webp?h=f728280d&itok=uNM1yRSN",
        link:"https://www.bakerhughes.com/company/news/baker-hughes-launches-consortium-exploring-technologies-transform-abandoned-wells"
    },
    {
        id:17,    
        title:"Germany aims for 100 new geothermal projects by 2030",
        date:"11 Nov 2022",
        image:"https://www.thinkgeoenergy.com/wp-content/uploads/2022/09/Bundestag.jpg",
        link:"https://www.thinkgeoenergy.com/germany-aims-for-100-new-geothermal-projects-by-2030/"
    },
    {
        id:18,    
        title:"Here's why geothermal systems could be better for storing renewable energy than batteries",
        date:"2 Nov 2022",
        image:"https://assets.weforum.org/article/image/responsive_big_webp_l-uIxCfj5Y15nlD62XRAiwc0ugW6bga4_G-5wY5q1_w.webp",
        link:"https://www.weforum.org/agenda/2022/11/geothermal-renewable-energy-storage/"
    },
    {
        id:19,    
        title:"Here's why geothermal systems could be better for storing renewable energy than batteries",
        date:"2 Nov 2022",
        image:"https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/B4UGXY3NLKEKGHVJEESQGB6WMM.JPG&w=767",
        link:"https://www.washingtonpost.com/climate-solutions/2022/11/01/croatia-geothermal-energy-europe/"
    },
    {
        id:20,    
        title:"This UFO-like structure could help Europe transform its energy",
        date:"1 Nov 2022",
        image:"https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/B4UGXY3NLKEKGHVJEESQGB6WMM.JPG&w=767",
        link:"https://www.washingtonpost.com/climate-solutions/2022/11/01/croatia-geothermal-energy-europe/"
    },
    {
        id:21,    
        title:"Global Investors Summit 2023 in UP all set to attract huge investments from USA companies - ET Government",
        date:"1 Nov 2022",
        image:"",
        link:"https://government.economictimes.indiatimes.com/news/governance/global-investors-summit-2023-in-up-all-set-to-attract-huge-investments-from-usa-companies/94957291"
    },
    {
        id:22,    
        title:"The Texas General Land Office has announced a Sealed Bid Lease Sale that includes six geothermal tracts in the County of El Paso.",
        date:"19 Oct 2022",
        image:"https://www.thinkgeoenergy.com/wp-content/uploads/2022/10/El-Paso.jpg",
        link:"https://www.thinkgeoenergy.com/texas-lease-sale-to-include-geothermal-tracts-in-el-paso-county/"
    }
]


const pressdata = [
    {
        id:1,    
        title:"Geothermal Core proposes geothermal power plant near Goliad County, Texas",
        date:"22 Mar 2023",
        image:"https://www.thinkgeoenergy.com/wp-content/uploads/2023/03/Goliad-Texas.jpg",
        link:"https://www.thinkgeoenergy.com/geothermal-core-proposes-geothermal-power-plant-near-goliad-county-texas/"
    },
    {
        id:2,    
        title:"Proposed geothermal plant for Crossroads would generate power from water deep underground",
        date:"15 Mar, 2023",
        image:"https://bloximages.newyork1.vip.townnews.com/victoriaadvocate.com/content/tncms/assets/v3/editorial/4/87/4874817e-bf76-11ed-9da4-8b97eb76f29d/640b7f1218dd7.image.png?resize=1200%2C675",
        link:"https://www.victoriaadvocate.com/counties/goliad/proposed-geothermal-plant-for-crossroads-would-generate-power-from-water-deep-underground/article_5846c7c8-bf72-11ed-bc6c-0358c2804306.html"
    },
    {
        id:3,    
        title:"Geothermal Core and GEG Power Partnering to Develop First Geothermal Power Plant in Texas",
        date:"2 Mar 2023",
        image:"",
        link:"https://finance.yahoo.com/news/geothermal-core-geg-power-partnering-162400913.html?guccounter=1&guce_referrer=aHR0cHM6Ly9zdGF0aWNzLnRlYW1zLmNkbi5vZmZpY2UubmV0Lw&guce_referrer_sig=AQAAANVs7bMsR1hAEVsOO1SmcYU0kkYJ0LaQZWgSceqXlQon9caOIFQwNYU5MxztN0i7YHxv4o15PwC8XROC1wP6ohmJgjKejSdKsvzCuiAaXZm_kOOlMgGj0TYIbUtdota3VKEPQsrjzRoDlo6ErWN6I5WKL1nv7EEZHBMYMzo5m07Z"
    },
    {
        id:4,    
        title:"GIS-23 to bring investment in 5 major sectors, says UP Government",
        date:"6 April 2023",
        image:"https://www.business-standard.com/_next/image?url=https%3A%2F%2Fbsmedia.business-standard.com%2F_media%2Fbs%2Fimg%2Farticle%2F2022-04%2F10%2Ffull%2F1649613220-9488.jpg%3Fim%3DFeatureCrop%2Cwidth%3D826%2Cheight%3D465&w=828&q=75",
        link:"https://www.business-standard.com/article/economy-policy/gis-23-to-bring-investment-in-5-major-sectors-says-up-government-123020800863_1.html"
    },
    {
        id:5,    
        title:"US company signs LoI to invest ₹820 cr to produce geothermal power in U.P.",
        date:"1 Feb 2023",
        image:"https://images.hindustantimes.com/img/2023/01/31/550x309/Geothermal-energy-is-a-mature-renewable-energy-tec_1675191277839.jpg",
        link:"https://www.hindustantimes.com/cities/lucknow-news/us-company-signs-loi-to-invest-rs-820-cr-to-produce-geothermal-power-in-up-101675191278883.html"
    },
    
]

export {NewsData, pressdata};
