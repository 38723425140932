import React from "react";
import Logo from "../../images/edge196_white.svg";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTelegramPlane,
  FaLinkedinIn,
  FaWhatsapp,
  FaMediumM,
} from "react-icons/fa";
// import NewsletterSignup from "../Newsletter/Newsletter.js";

const StyledFooter = styled.footer`
  background-color: #070f34 !important;
  color: #ffffff;
  font-size: 14px;

  .newsletter {
    justify-content: center;
  }
  #copyright {
    color: #e5e5e5;
    font-size: 0.9em;
  }

  .section-title {
    text-transform: uppercase;
    /* letter-spacing: 3px; */
    font-size: 14px;
  }

  .office-location,
  .office-address {
    font-size: 0.9em;
    color: #e5e5e5;
  }

  a:hover,
  svg.social-btn:hover {
    color: #f05139;
    fill: #f05139;
  }

  .some-links a:not(:last-child) {
    margin-right: 15px;
  }

  @media screen and (max-width: 541px) {
    .logo-and-icons {
      margin-top: 120px;
      text-align: center;
    }

    .social-icons {
      justify-content: center;
    }

    .some-links a {
      font-size: 12px;
    }

    .section-title {
      font-size: 12px;
    }

    li a {
      font-size: 12px;
    }

    #copyright {
      padding-bottom: 15px;
      text-align: center;
    }

    .second-row-mobile,
    .third-row-mobile {
      padding-top: 15px;
    }
  }

  @media screen and (min-width: 566px) and (max-width: 752px) and (orientation: landscape) {
    .logo-and-icons {
      margin-top: 100px;
      text-align: center;
    }

    .social-icons {
      justify-content: center;
    }

    #copyright {
      padding-bottom: 15px;
      text-align: center;
    }

    .second-row-mobile,
    .third-row-mobile {
      padding-top: 15px;
    }
  }
  @media screen and (min-width: 542px) and (max-width: 770px) {
    .section-title {
      font-size: 12.5px;
    }

    li a {
      font-size: 12.5px;
    }
  }
`;
const CompanyLogo = styled.img`
  width: 10rem;
`;
const IconStyle = { color: "white", fontSize: "1.8em", paddingRight: "10px" };

const DisclaimerSection = styled.div`
  margin-top: -12px;
  padding-top: 3em;
  padding-bottom: 3em;
  padding-left: 4em;
  padding-right: 4em;
  width: 100%;
  background-color: #f5f5f5;
  text-align: center;
  font-size: 12px;
  font-family: mulibold;

  @media screen and (max-width: 412px) {
    /* padding-left: 2em;
    padding-right: 2em; */
  }
`;

const FooterRedux = () => {
  return (
    <>
      <StyledFooter>
        <Container className="pt-5 pb-5 wow fadeIn">
          <Row>
            <Col xs={12} sm={4} md={3} className="">
              <div>
                <p className="ff-muli-b section-title">About Us</p>
                <ul className="ff-muli-l">
                  <li>
                    <a href="/our-story">Our Story</a>
                  </li>
                  <li>
                    <a href="/our-solution">Our Solution</a>
                  </li>
                  <li>
                    <a href="/team">Our Team</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xs={12} sm={4} md={3} className="second-row-mobile">
              <div className="b-s-20-430">
                <p className="ff-muli-b section-title">Our Community</p>
                <ul className="ff-muli-l">
                  <li>
                    <a href="/partners">Partners</a>
                  </li>
                  <li>
                    <a
                      href="https://www.labs196.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Accelerator Program{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={4} md={3} className="second-row-mobile">
              <div className="b-s-20">
                <p className="ff-muli-b section-title">Get in Touch</p>
                <ul className="ff-muli-l">
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  {/* <li>
                    <a href="/work-with-us">Work With Us </a>
                  </li> */}
                </ul>
              </div>
            </Col>
            {/* <Col xs={6} md={3} className="b-s-20 third-row-mobile">
              <div>
                <div className="">
                  <p className="ff-muli-b section-title">Help</p>
                  <ul className="ff-muli-l">
                    <li>
                      <a href="/contact-us">Contact Us</a>
                    </li>
                     <li>
                      <a href="/faqs">FAQs</a>
                    </li> 
                  </ul>
                </div>
              </div>
            </Col> */}
          </Row>

          <Row className="footerAddress">
            {/* <Col md={12} className="pt-2">
              <Row> */}
            <Col xs={12} md={6}>
              <p className="ff-muli-b office-location">USA Office</p>
              <p className="ff-muli-l office-address">
                2150 Town Square Place, Suite 200
              </p>
              <p className="ff-muli-l office-address">
                Sugar Land, Texas 77479
              </p>
            </Col>
            {/* </Row>
            </Col> */}
            <Col xs={12} md={6} className="newsletter">
              <iframe
                frameBorder="0"
                id="iframewin"
                width="100%"
                height="100%"
                src="https://gcyv.campaign-view.com/ua/Optin?od=11287ecb5358af&zx=12b102e83&lD=19d73ab16a45e39d&n=11699f74cd4189e&sD=19d73ab16a639937"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </StyledFooter>
      <DisclaimerSection>
        <p className="text-uppercase">
          EDGE-Q MAY ONLY BE OFFERED TO ACCREDITED & INSTITUIONAL U.S. INVESTORS
          AND ALL NON-U.S. INVESTORS, WITH CERTAIN OTHER QUALIFICATIONS AS THE
          COMPANY MAY REQUIRE.
        </p>
      </DisclaimerSection>
    </>
  );
};

export default FooterRedux;
