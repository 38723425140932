import React from "react";
// import MetaTags from 'react-meta-tags';

const PageNotFound = () => {
  return (
    <div className="notFoundWrapper">
      {/* <MetaTags>
            <meta id="meta-description" name="description" content="This page could not be found" />
          </MetaTags>
            <img alt='notFound' src="https://i.imgur.com/qIufhof.png" />
            <div className="info">
                <h3>This page could not be found</h3>
            </div> */}
    </div>
  );
};

export default PageNotFound;
