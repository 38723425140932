import React from "react";
import { Parallax } from "react-parallax";
import PageTitle from "../reusecomponents/PageTitle";

import bgImage from "../../images/full_overlay.png";

const Edge196Twitter = () => {
  return (
    <React.Fragment>
      <main>
        <section className="list-of-con-map-img after-po position-relative p-0">
          <Parallax
            blur={0}
            bgImage={bgImage}
            bgImageAlt="VENTURE CAPITAL INVESTING"
            strength={300}
          >
            <div className="eco-system-se position-relative before-bg-none partners-shapes">
              <div
                className="for-shape position-absolute after-bg-none wow fadeIn"
                data-wow-delay="0.2s"
              ></div>
              <div className="container">
                <PageTitle
                  backLink="/"
                  backText="Home"
                  correntPage="Portfolio Spotlights"
                  title="EDGE196"
                  title2="Edge196 on Twitter"
                  text={`Our Edge196 on Twitter are below`}
                />
              
              </div>
            </div>
          </Parallax>
        </section>
        <section className="recent-media-se bg-white">
          <div className="container">
            <h3 className="text-uppercase ff-muli-b wow fadeIn">Recent</h3>
            <div className="d-flex flex-wrap justify-content-between">
              {/* <div className="sm-w media-corn  wow zoomIn">
                <div className="d-flex flex-wrap-479">
                  <div className="im-se">
                    <a
                      href="https://edge196.medium.com/these-5-emerging-startup-hubs-in-asia-may-soon-outpace-silicon-valley-ebde53843ee7"
                      className="text-uppercase ff-muli-l"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://miro.medium.com/max/2000/1*C_k4d5W_Q8jB6BXuClT01w.png"
                        alt="Asia Startups"
                        className="object-fit-cover"
                      />
                    </a>
                  </div>
                  <div className="info-se">
                    <small className="text-uppercase ff-muli-r">Edge-x</small>
                    <a
                      href="https://edge196.medium.com/these-5-emerging-startup-hubs-in-asia-may-soon-outpace-silicon-valley-ebde53843ee7"
                      className="text-uppercase ff-muli-l"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h4 className="ff-muli-l">
                        These 5 emerging startup hubs in Asia may soon outpace
                        Silicon Valley
                      </h4>
                    </a>
                    <p className="ff-muli-r line-clamp">
                      Despite the deterring conditions of Covid-19, many startup
                      harbors in Asia continue to flourish. Here are 5 emerging
                      startup hubs in Asia you should know about.
                    </p>
                    <a
                      href="https://edge196.medium.com/these-5-emerging-startup-hubs-in-asia-may-soon-outpace-silicon-valley-ebde53843ee7"
                      className="text-uppercase ff-muli-l"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Full Story
                    </a>
                  </div>
                </div>
              </div>
              <div className="sm-w media-corn wow zoomIn">
                <div className="d-flex flex-wrap-479">
                  <div className="im-se">
                    <a
                      href="https://edge196.medium.com/top-5-startup-competitions-in-asia-every-entrepreneur-needs-to-know-now-fc73250e6bf3"
                      className="text-uppercase ff-muli-l"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://miro.medium.com/max/1400/1*0-uhQqofg8Eab--6Ks7iCQ.jpeg"
                        alt="Traditional VC"
                        className="object-fit-cover"
                      />
                    </a>
                  </div>
                  <div className="info-se">
                    <small className="text-uppercase ff-muli-r">Edge-x</small>
                    <a
                      href="https://edge196.medium.com/top-5-startup-competitions-in-asia-every-entrepreneur-needs-to-know-now-fc73250e6bf3"
                      className="text-uppercase ff-muli-l"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h4 className="ff-muli-l">
                        Top 5 startup competitions in Asia every entrepreneur
                        needs to know now
                      </h4>
                    </a>
                    <p className="ff-muli-r line-clamp">
                      For anyone desiring to give wings to their venture,
                      startup contests are the best way to begin. Today, we
                      share the top five startup competitions in Asia
                    </p>
                    <a
                      href="https://edge196.medium.com/top-5-startup-competitions-in-asia-every-entrepreneur-needs-to-know-now-fc73250e6bf3"
                      className="text-uppercase ff-muli-l"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Full Story
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <Parallax
          blur={0}
          bgImage={bgImage}
          bgImageAlt="VENTURE CAPITAL INVESTING"
          strength={200}
        >
          <div className="load-more position-relative after-po">
            <div className="container"></div>
          </div>
        </Parallax>
      </main>
    </React.Fragment>
  );
};
export default Edge196Twitter;
