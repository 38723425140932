import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CheckLogin } from "./adminUtil";
import LeftMenu from "./LeftMenu";

const ProjectContact = () => {
  const [teamData, setTeamData] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [dragId, setDragId] = useState();
  useEffect(() => {
    getTeam();
    if (sessionStorage.getItem("token")) {
      setIsLogin(true);
    }
  }, []);

  const getTeam = async () => {
    const res = await axios.get(
      "https://prod-api.edge196.com/api/contactus/project-contact"
    );

    if (res.status === 200) {
      setTeamData(res.data.message);
    }
  };
 
  const handleDragStart = (e) => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = (e) => {
    console.log(e.currentTarget.id);
    const dragItem = teamData.find((person) => person._id === dragId);
    const dropItem = teamData.find(
      (person) => person._id === e.currentTarget.id
    );

    const newTeamData = teamData.map((person) => {
      if (person._id === dragId) {
        return dropItem;
      }
      if (person._id === e.currentTarget.id) {
        return dragItem;
      }
      return person;
    });
    setTeamData(newTeamData);
  };
console.log(teamData)
  return (
    <div className="viewDataWrapper">
      <LeftMenu/>
      <h2>
        Project Contact
      </h2>
      {isLogin ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>contact</th>
              <th>Project</th>
            </tr>
          </thead>
          <tbody>
            {teamData &&
              teamData.map((data) => (
                <tr
                  key={data._id}
                  id={data._id}
                  draggable={true}
                  onDragStart={handleDragStart}
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <td>{data.name}</td>
                  <td>{data.email}</td>
                  <td>{data.contact}</td>
                  <td>{data.project}</td>
                 
                  
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <CheckLogin setIsLogin={setIsLogin} />
      )}
    </div>
  );
};

export default ProjectContact;
