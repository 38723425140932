import { Spin } from "antd";
import React from "react";

export default class SuspenseLoader extends React.Component {
  render() {
    return (
      <div
        className="loader suspense-loader"
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
}
