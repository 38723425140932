import React, { useEffect } from "react";
import WOW from "wowjs";

const PageTitle = (props) => {
  const { isWow = true } = props;
  useEffect(() => {
    const wow = new WOW.WOW();
    isWow && wow.init();
  });
  return (
    <div className="h-100 d-flex flex-wrap wow fadeInUp mt-3 ">
      <div className="container">
        <ul className="text-uppercase ff-muli-l breadcrumbs-div d-flex align-items-center position-relative z-9">
          <li>
            <a href={props.backLink}>{props.backText}</a>
          </li>
          <li className="position-relative arrow-ic">&#62;</li>
          <li>{props.correntPage}</li>
        </ul>
        <div
          className={`position-relative z-9 txt-part-se ${props.classTitleMain}`}
        >
          <div className="main-title text-uppercase text-white">
            <h2
              className="ff-muli-b text-white"
              dangerouslySetInnerHTML={{ __html: props.title }}
            ></h2>
            <h1
              className={`ff-b-condensed  text-white ${props.colorGray}`}
              dangerouslySetInnerHTML={{ __html: props.title2 }}
            ></h1>
          </div>
          <div className="text-contain ff-muli-l">
            <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageTitle;

export const PageTitle2 = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  });

  return (
    <div className="container wow fadeInUp">
      <ul className="text-uppercase ff-muli-l breadcrumbs-div d-flex align-items-center position-relative z-9">
        <li>
          <a href={props.backLink}>{props.backText}</a>
        </li>
        <li className="position-relative arrow-ic">&#62;</li>
        <li>{props.correntPage}</li>
      </ul>
      <div className={`position-relative z-9`}>
        <div className="main-title text-uppercase">
          {props.displayH2 && (
            <h2
              className="ff-muli-b _color_gray"
              dangerouslySetInnerHTML={{ __html: props.title }}
            ></h2>
          )}
          <h1
            className={`ff-b-condensed _color_gray`}
            dangerouslySetInnerHTML={{ __html: props.title2 }}
          ></h1>
          {props.lastLine && (
            <p className="edge196_mt_10">{props.LastLineText}</p>
          )}
          <p></p>
        </div>
      </div>
    </div>
  );
};

export const PageTitle3 = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  });

  return (
    <>
      <div className={`f10-muli-400 text-uppercase pb-3 ${props.titleClass}`}>
        {props.title}
      </div>
      <div
        className={`f24-muli-700 text-uppercase letterspacing-4 edge196_text_darkBlue pb-3 ${props.subtitleClass}`}
      >
        {props.subtitle}
      </div>
      <div className={`f14-muli-400 ${props.textClass}`}>{props.text}</div>
    </>
  );
};
