import React, { useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import "../../css/edge.css";
// QR Code
import bcard from "../../images/project/qr/Edge-Bcard.png";
import docs from "../../images/project/qr/Edge-docs.png";
import lctqr from "../../images/project/qr/LCT.png";
import trancqr from "../../images/project/qr/TE.png";
import gtqr from "../../images/project/qr/GT.png";
import rempqr from "../../images/project/qr/REMP.png";
import ghqr from "../../images/project/qr/Generation.png";
import tmqr from "../../images/project/qr/TMQR.png";
import finixqr from "../../images/project/qr/Finix.png";
import rxqr from "../../images/project/qr/Rx4.png";
import techqr from "../../images/project/qr/Tech.png";
import cremqr from "../../images/project/qr/cremtex.png";
// Logo
import lct from "../../images/project/logo/lct-logo.png";
import te from "../../images/project/logo/te-logo.png";
import geothermal from "../../images/project/logo/gt-logo.png";
import remp from "../../images/project/logo/remp-logo.png";
import gh from "../../images/project/logo/generation-logo.png";
import tm from "../../images/project/logo/tmqr-logo.png";
import finix from "../../images/project/logo/finix-logo.png";
import rx from "../../images/project/logo/rx4-logo.png";
import tech from "../../images/project/logo/tech196-logo.png";
import cremtex from "../../images/project/logo/cremtex-logo.png";
import axios from "axios";


const ConnectNow = ({project, logo}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [data, setData] = useState({project});
  const onChange = ({target}) =>{
    const {name, value} = target;
    setData({...data, [name]: value});
  };
  const onSubmit = async(e)=>{
    const res = await axios.post(
      "https://prod-api.edge196.com/api/contactus/add-project-contact",
      data
    );
    if(res.status === 200){
      alert("We will connect you soon!")
      handleClose()
    }
    else{
      console.log("somethig went wrong!")
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} className="projectModal">
        <Modal.Header closeButton>
          <div className="modal-head-logo">
            <img src={logo} alt={project} />
          </div>
        </Modal.Header>

        <Modal.Body>
          <input type="text" className="form-control" placeholder="Full Name*" name='name' onChange={onChange}/>
          <input type="email" className="form-control" placeholder="Email Address*" name='email' onChange={onChange}/>
          <input type="number" className="form-control" placeholder="Phone Number*" name='contact' onChange={onChange}/>

          <button className="submit-btn" onClick={onSubmit}>Let&apos;s Connect</button> 
        </Modal.Body>
      </Modal>
      <p className="connect-now">
        <span onClick={() => setShow(!show)}>Connect Now</span>
      </p>
    </>
  );
};

const Product = () => {
  return (
    <div className="edge-section">
      <Row className="justify-content-center gy-4 hidden">
        <Col md="6" lg="4" xl="3" xxl="2">
          <div className="qr-box-wrap">
            <h5 className="qr-title text-center">Edge Document</h5>
            <div className="qr-box">
              <img src={bcard} alt="" />
            </div>
          </div>
        </Col>

        <Col md="6" lg="4" xl="3" xxl="2" className="mt-sm-20px">
          <div className="qr-box-wrap">
            <h5 className="qr-title text-center">Business Card</h5>
            <div className="qr-box">
              <img src={docs} alt="" />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center mt-4 gy-4">
        <Col xl="12" xxl="12">
          <div className="qr-box-wrap">

            <Row className="gy-4">
              

             

              <Col md="4" className="mt-sm-20px">
                <a
                  href="https://www.geothermalcore.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="qr-logo-box">
                    <img src={geothermal} alt="" />
                  </div>
                </a>
                <ConnectNow logo = {geothermal} project = 'geothermalcore'/>
                <div className="qr-box">
                  <img src={gtqr} alt="" />
                </div>
              </Col>
              <Col md="4" className="mt-sm-20px">
              <a href="https://rx4.health/" target="_blank" rel="noreferrer">
                  <div className="qr-logo-box">
                    <img src={rx} alt="" />
                  </div>
                </a>
                <ConnectNow logo = {rx} project = 'rx4-health'/>
                <div className="qr-box">
                  <img src={rxqr} alt="" />
                </div>
                </Col>
                <Col md="4" className="mt-sm-20px">
                <a
                  href="https://www.tech196.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="qr-logo-box">
                    <img src={tech} alt="" />
                  </div>
                </a>
                <ConnectNow logo = {tech} project = 'tech196'/>
                <div className="qr-box">
                  <img src={techqr} alt="" />
                </div>
                </Col>
            </Row>
          </div>
        </Col>

      
      </Row>

     
    </div>
  );
};

export default Product;
